import styled, { withTheme } from "styled-components";
import { useRecoilValue } from "recoil";
import { CloseButton, H2, H3, Link } from "@sussex/react-kit/elements";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import providerState from "../../state/provider";
import userState from "../../state/user";
import ProgressBar from "../ProgressBar";
import useCopy from "../../hooks/useCopy";
import { close } from "../../utils";
import { logoutUser } from "../../httpapi";

const HeaderWrapper = styled.div`
  width: 100%;
  position: sticky;
  top: 0;
  background: #fff;
  padding-top: 25px;
`;

const ContentWrapper = styled.div`
  display: flex;
  padding: 0 0 24px;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

const LogoutWrapper = styled.div`
  margin: -20px -25px 14px;
  background-color: ${({ theme }) => theme.colors.light};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.small};
  line-height: 1.8;
  padding: 10px 24px 8px;
`;

const TextWrapper = styled.div``;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CloseWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StepNumber = styled(H3)`
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme }) => theme.colors.secondary};
`;

const Title = styled(H2)`
  color: ${({ theme }) => theme.colors.default};
  line-height: 1;
`;

const ProgressWrapper = styled.div`
  padding-bottom: 24px;
`;

const Photo = styled.img`
  display: block;
  width: 45px;
  height: 56px;
  border-radius: 8px;
  margin-right: 10px;
  object-fit: cover;
`;

const Header = ({ currentStep, totalSteps, title, theme }) => {
  const showProgressBar = !!currentStep;
  const userData = useRecoilValue(userState);
  const [logoutText, logoutLinkText] = useCopy([
    "bookNow.panelHeader.logout",
    "bookNow.panelHeader.logoutLink",
  ]);
  const { photoUrl } = useRecoilValue(providerState);

  const handleLogout = async () => {
    const { success } = await logoutUser();
    if (success) {
      close();
    }
  };

  return (
    <HeaderWrapper>
      {userData.signedIn && (
        <LogoutWrapper>
          {swapCopyVariables(logoutText, {
            NAME: userData.name,
            LOGOUT_LINK: <Link onClick={handleLogout}>{logoutLinkText}</Link>,
          })}
        </LogoutWrapper>
      )}
      <ContentWrapper>
        <TitleWrapper>
          <Photo src={photoUrl}></Photo>
          <TextWrapper>
            {currentStep && <StepNumber>Book Now</StepNumber>}
            <Title>{title}</Title>
          </TextWrapper>
        </TitleWrapper>
        <CloseWrapper>
          <CloseButton
            close={close}
            width={"14px"}
            height={"14px"}
            fill={theme.colors.default}
            id="stepform-close"
            type="button"
          />
        </CloseWrapper>
      </ContentWrapper>
      <ProgressWrapper showBorder={!showProgressBar}>
        {showProgressBar && (
          <ProgressBar currentStep={currentStep} totalSteps={totalSteps} />
        )}
      </ProgressWrapper>
    </HeaderWrapper>
  );
};

export default withTheme(Header);
