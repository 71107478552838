import { atom, selector } from "recoil";
import providerState from "./provider";
import { requestPreviousRequests, requestProviderProfile } from "../httpapi";
import { getIDPCredentials, decryptText } from "../idp";
import { STATUSES_ACTIVE } from "@sussex/react-kit/conversations";

const user = atom({
  key: "User", // unique ID (with respect to other atoms/selectors)
  default: {
    username: null,
    signedIn: false,
    verified: false,
    name: "",
    phone: "",
    email: "",
    locale: "",
  }, // default value (aka initial value)
});

const getProfiles = async profileUuids => {
  const profileRequests = profileUuids.map(p => requestProviderProfile(p));
  const profiles = await Promise.all(profileRequests);
  return profiles;
};

const decryptMessages = async messages => {
  const credentials = await getIDPCredentials();
  const decryptRequests = messages.map(m => {
    if (m && credentials) {
      return Promise.resolve(decryptText(credentials, m));
    }
    return Promise.resolve("");
  });
  const decryptedMessages = await Promise.all(decryptRequests);
  return decryptedMessages;
};

const unexpiredRequests = selector({
  key: "User.UnexpiredRequests",
  get: ({ get }) => {
    const history = get(requestHistory);

    if (!history) {
      return [];
    }

    const now = Math.floor(Date.now() / 1000);
    return history.filter(r => {
      if (STATUSES_ACTIVE.indexOf(r.status) === -1) {
        return false;
      }

      if (r.proposedDatetime > 0) {
        if (r.proposedDatetime < now) {
          return false;
        }
      } else if (r.datetime < now) {
        return false;
      }

      return true;
    });
  },
});

const activeRequests = selector({
  key: "User.ActiveRequests",
  get: ({ get }) => {
    const history = get(requestHistory);

    if (!history) {
      return [];
    }

    return history.filter(r => STATUSES_ACTIVE.indexOf(r.status) >= 0);
  },
});

const requestHistory = selector({
  key: "User.RequestHistory",
  get: async ({ get }) => {
    if (!get(user).signedIn || !get(user).verified) {
      return null;
    }
    try {
      const reqRes = await requestPreviousRequests();
      if (!reqRes.success) {
        return null;
      }

      // Get all requests and ensure they are in order of new to old
      const requests = reqRes.requests.sort(
        (a, b) => a.createdAt > b.createdAt,
      );

      // Get profile data and decrypt messages for each request
      const [profiles, messages] = await Promise.all([
        getProfiles(requests.map(r => r.profileUuid)),
        decryptMessages(requests.map(r => r.message)),
      ]);
      requests.forEach((r, i) => {
        const profile = profiles[i].profile;
        r.profilePhotoUrl = profile.photoUrl;
        r.profileName = profile.name;
        r.message = messages[i];
      });
      return requests;
    } catch (e) {
      console.error(e);
      return null;
    }
  },
});

const locale = selector({
  key: "User.Locale",
  get: async ({ get }) => {
    const userData = get(user);
    if (userData.locale) {
      return userData.locale;
    }
    const providerData = get(providerState);
    if (providerData.languageCode && providerData.countryCode) {
      return `${providerData.languageCode}-${providerData.countryCode}`;
    }
    return navigator.language;
  },
});

export default user;

export { requestHistory, activeRequests, unexpiredRequests, locale };
